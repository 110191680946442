import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Stack, Button, Tooltip, IconButton } from '@mui/material'
import ConfirmDialog from '../../../components/confirm-dialog/ConfirmDialog'
import useResponsive from '../../../hooks/useResponsive'
import Iconify from '../../../components/iconify/Iconify'
import { isExceedingCampaignLimit, pipelineSelector } from '../../../../redux/selectors'
import { useSelector } from 'react-redux'
import useTranslation from '../../../../localization/useTranslation'

export const PipelineDetailsToolbar = ({
  fileInputRef,
  taskName,
  taskStatus,
  onDelete,
  onCloseDetails,
  handleActiveInCampaign,
}) => {
  const { labelStrings } = useTranslation()

  const isDisabledByCampaignLimit = useSelector(isExceedingCampaignLimit)
  const { isOutOfCampaignsSeats } = useSelector(pipelineSelector)

  const isDesktop = useResponsive('up', 'sm')

  const [openConfirm, setOpenConfirm] = useState(false)

  const handleOpenConfirm = () => {
    setOpenConfirm(true)
  }

  const handleCloseConfirm = () => {
    setOpenConfirm(false)
  }

  const handleDeleteCard = () => {
    onDelete()
    handleCloseConfirm()
  }

  return (
    <>
      <Stack p={2.5} direction="row" alignItems="center">
        {!isDesktop && (
          <Tooltip title="Back">
            <IconButton onClick={onCloseDetails} sx={{ mr: 1 }}>
              <Iconify icon="eva:arrow-ios-back-fill" />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip
          title={
            isDisabledByCampaignLimit || isOutOfCampaignsSeats
              ? labelStrings.disabledTextForCampaignLimit
              : ''
          }
          placement="bottom-start"
          arrow
        >
          <div>
            <Button
              size="small"
              color="success"
              disabled={
                taskStatus === 'active' || isDisabledByCampaignLimit || isOutOfCampaignsSeats
              }
              variant={taskStatus === 'active' ? 'outlined' : 'contained'}
              onClick={handleActiveInCampaign}
            >
              {'Activate in Campaign'}
            </Button>
          </div>
        </Tooltip>

        <Stack direction="row" spacing={1} justifyContent="flex-end" flexGrow={1}>
          <Tooltip title="Delete Creator">
            <IconButton onClick={handleOpenConfirm} size="small">
              <Iconify icon="eva:trash-2-outline" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      <input ref={fileInputRef} type="file" style={{ display: 'none' }} />

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content={
          <>
            Are you sure you want to delete <strong> {taskName} </strong>?
          </>
        }        
        action={
          <Button variant="contained" color="error" onClick={handleDeleteCard}>
            Delete
          </Button>
        }
      />
    </>
  )
}

PipelineDetailsToolbar.propTypes = {
  onAttach: PropTypes.func,
  onDelete: PropTypes.func,

  taskName: PropTypes.string,
  taskStatus: PropTypes.string,

  fileInputRef: PropTypes.object,
  onCloseDetails: PropTypes.func,
  handleActiveInCampaign: PropTypes.func,
}
